import { SectionParser, useGetLocalesQuery } from '@front/master';
import { Section } from '@shared/master-types';
import React from 'react';

import { CustomSpaceImageRow, TCustomImagesRow } from '../../../rows';

export type TSectionsListProps = {
  sections: Section[];
  locale: string;
  draft: boolean;
};

export const SectionsList: React.FC<TSectionsListProps> = props => {
  const { sections, draft, locale } = props;

  const { data: localesObject } = useGetLocalesQuery({ locale, draft });

  const locales = localesObject?.docs;

  const customSectionRowRenderer = (row: TCustomImagesRow): React.ReactNode => {
    if (
      ['space-gui-image', 'space-affiliate-program-image'].includes(
        row.customType,
      )
    ) {
      return <CustomSpaceImageRow key={row.id} row={row} />;
    }

    return undefined;
  };

  return (
    <>
      {sections?.map(section => (
        <SectionParser
          key={section.id}
          section={section}
          locales={locales}
          customSectionRowRenderer={customSectionRowRenderer}
        />
      ))}
    </>
  );
};
